import style from '../css/OneTimePassword.module.css';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../assets/aanaab-logo2.jpg';
import axios from 'axios';

var otp = '';
var wrongMsg = {AR:'',EN:''};
var runTimer = false;

function OneTimePassword ({toggle, phone, requestCode}){
  const navigate = useNavigate();
  const [ResetPassState, setResetPassState] = useState({otpOK: false, Progress: false, serverErr: false,success:false});
  const [time, setTime] = useState(30);
  const [tokens, setTokens] = useState({flag: false, token: null});

  const onKeyDown = (e)=>{
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      ResetAction();
    }
  }

  const handleChange = (e)=> {
    if(e.target.name=='otp'){
      if((/\d/.test(e.target.value.substring(e.target.value.length-1)) && e.target.value.length <= 4) || e.target.value === ''){
        otp = e.target.value;
        if(otp.length == 4)
          setResetPassState({otpOK: true, Progress: ResetPassState.Progress,serverErr: false,success:false});
        else
          setResetPassState({otpOK: false, Progress: ResetPassState.Progress, serverErr: false,success:false});
    }
  }
}

  useEffect(() => {
    var timer;
    timer = setInterval(()=>{
      setTime(time-1)
    },1000)
    if(time == 0)
      clearInterval(timer);
    return ()=> clearInterval(timer);
  }, [time]);


  useEffect(()=>{
    if(tokens.flag){
      localStorage.setItem("token", tokens.token);
      navigate('/home');
    }
  },[tokens.flag]);


  const resetTime=()=>{
    setTime(30);
    if(!requestCode()){
      wrongMsg.AR = 'عدة محاولات فاشلة لنفس الرقم الرجاء التواصل مع الدعم الفني';
      setResetPassState({otpOK:ResetPassState.otpOK, Progress: false, serverErr:true,success:false});
    }
  }


  const ResetAction = ()=>{
    if(!ResetPassState.Progress){
      if(ResetPassState.otpOK){
        setResetPassState({otpOK:ResetPassState.otpOK, Progress: true, serverErr: false, success:false});
        axios.post('https://books.aanaab.com/api/login/verify/otp',{code:otp, phone: phone},{headers:{authorization: process.env.REACT_APP_AUTH_KEY,accept:'application/json'}})
          .then(response=>{
            if (response.data.responseCode==1){
                setTokens({flag: true, token: response.data.token});
            }
            else{
                wrongMsg.AR= 'الرمز المدخل غير صحيح';
                setResetPassState({otpOK:false, Progress: false, serverErr:true,success:false});
            }
          })
          .catch(error=>{
              console.log(error);
              wrongMsg.AR = ' حصل خطأ نرجو المحاولة مرة اخرى';
              setResetPassState({otpOK:ResetPassState.otpOK, Progress: false, serverErr:true,success:false});
          });
      }
    }
  }

  return (
    <div className={style.ResetPassPage}>
      <div className={!ResetPassState.serverErr ? `${style.LoginFailedBox} ${style.hidden}` : ResetPassState.success ? `${style.LoginFailedBox} ${style.greenBox}` : `${style.LoginFailedBox} ${style.yellowBox}`}>
        <p className={`${style.LoginFailedText} ${style.Arabic}`}>{wrongMsg.AR}</p>
      </div>
      <img src={logo} className={style.Logo} alt="" />
      <div className={style.ResetPassBox}>
        <p className={`${style.ResetPassHeaderText} ${style.Arabic}`}>{"التحقق من الدخول"}</p>
        <p className={`${style.ResetPassSubHeaderText} ${style.Arabic}`}> {"يرجى ادخال رمز التحقق المرسل لجوالكم"}</p>
        <form className={style.form}>
            <input className={`${style.EmailPassword} ${style.English}`} name="otp" value={otp} onChange={handleChange} placeholder={"رمز التحقق"} onKeyDown={onKeyDown} />
        </form>
          <button className={!ResetPassState.Progress && ResetPassState.otpOK ? `${style.ResetButton} ${style.ActiveButton} ${style.Arabic}` : `${style.ResetButton} ${style.Arabic}`} onClick={()=> ResetAction()}>{"التحقق"}</button>
      </div>
      <p className={time == 0 ? style.timer : `${style.timer} ${style.notActive}`} onClick={()=> time ==0 ? resetTime() : null}>{time > 0 ? time+' الوقت المتبقي لطلب رمز جديد' : 'طلب رمز جديد'}</p>
      <p className={`${style.Back} ${style.Arabic}`} onClick={()=> !ResetPassState.Progress ? toggle() : null}>{"العودة"}</p>
      <span className={!ResetPassState.Progress ? style.ProgressBar : `${style.ProgressBar} ${style.ProgressBarMovment}`}></span>
    </div>
  );
}

export default OneTimePassword;
