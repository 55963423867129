import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../css/Home.module.css';
import logo from '../assets/aanaab-logo.jpg';
import greeting from '../assets/greeting1.1.jpg';
import wt from '../assets/whatsapp.png';
import li from '../assets/linkedIn.png';
import tw from '../assets/twitter.png';
import ig from '../assets/instagram.png';
import loader from '../assets/loader2.gif';
import axios from 'axios';

var signout = false;
var readFlag = false;

function Home({readerState, setReaderState}){
  const navigate = useNavigate();
  const [HomeState, setHomeState] = useState({nav: true, loading: true, settings: false});

  window.addEventListener("popstate", e => {
  // Nope, go back to your page
    navigate('/home');
  });

  useEffect(()=>{
    if(readFlag){
      readFlag = false;
      navigate('/home/reader');
    }else {
        axios.get('https://books.aanaab.com/api/home/reader',{headers:{authorization: process.env.REACT_APP_AUTH_KEY, Accept:'application/json', token: localStorage.getItem("token")}})
          .then(response=>{
              if(response.data.responseCode == 1){
                setTimeout(()=>{
                  setReaderState({courses: response.data.courses, course: null});
                  setHomeState({nav: HomeState.nav, loading: false , settings: HomeState.settings});
                },3000);
              }else{
                localStorage.removeItem("token");
                window.location.replace('https://books.aanaab.com');
              }
          })
        .catch(err =>{
          //localStorage.removeItem("token");
          navigate('/notfound');
        })
    }
  },[HomeState.nav])

  useEffect(()=>{
    if(signout){
      localStorage.removeItem("token");
      window.location.replace('https://books.aanaab.com');
    }
  },[HomeState.settings])

  const logout=()=>{
    signout = true;
    setHomeState({nav: HomeState.nav, loading: HomeState.loading , settings: !HomeState.settings});
  }


  const read = (id)=>{
      readFlag = true;
      setHomeState({nav: !HomeState.nav, loading:false, settings: HomeState.settings});
      setReaderState({courses: readerState.courses, course: readerState.courses[id]});
  }


return(
  <div className={style.adminPage}>
    <div className={style.topSection}>
      <div className={style.settingsBox}><p className={style.logout} onClick={()=> logout()}>الخروج</p></div>
      <div className={style.logoBox}>
        <img src={logo} className={style.logo} alt="" />
      </div>
      <div className={style.settingsBox}>
        <p className={style.settings} onClick={()=> setHomeState({nav: HomeState.nav, loading: HomeState.loading , settings: !HomeState.settings})}>الإعدادات</p>
      </div>
    </div>
    <span className={style.line}></span>
    {HomeState.settings ? <div className={style.settingsMenu}>
    <p className={style.settingsMenuItem} onClick={()=> logout()}>تسجيل الخروج</p>
    <p className={style.settingsMenuItem} onClick={()=> window.open("https://wa.me/+966506464663", '_blank')}>الدعم الفني</p>
    </div> : null}
    {HomeState.loading ? <div className={style.background}><img src={loader} className={style.loader} alt="" /></div> : <div className={style.adminPage}>
    <div className={style.greetingsSection}>
      <img src={greeting} className={style.greetingImage} alt="" />
      <div className={style.greetingTextBox}>
        <p className={style.greetingText}>مرحبا {readerState.courses[0].name}</p>
        <span style={{height:"10px", width:"100%"}}></span>
        <p className={style.greetingsubText}>قراءة كتبكم الالكترونية اسهل مع قارئ اعناب</p>
        <p className={style.greetingsubText}>تصفحوا قائمة الكتب الواسعة من متجر اعناب اليوم</p>
      </div>
    </div>
    <div className={style.Library}>
      <p className={style.LibraryHeader}>المكتبة</p>
      <div className={readerState.courses.length > 3 ? style.ThumbnailsGrid : style.ThumbnailFlex}>
          {readerState.courses.map((course, id)=> (
              <div key={id} className={style.book} onClick={()=> read(id)}>
                <img src={require('../assets/covers/'+course.cover_id+'.jpg')} className={style.bookThumbnail} alt="" />
                <p className={style.bookTitle}>{course.title}</p>
              </div>
          ))}
      </div>
       <div className={style.onlineStoreSection}>
        <p className={style.onlineStoreText}>يمكنكم شراء المزيد من الكتب عن طريق زيارة متجرنا الإلكتروني</p>
        <button className={style.onlineStoreButton} onClick={()=> window.open("https://shop.aanaab.com", '_blank')}>الذهاب للمتجر</button>
        <div className={style.socialSection}>
          <img src={wt} className={style.icon} alt="" onClick={()=> window.open("https://wa.me/+966506464663", '_blank')}/>
          <img src={li} className={style.icon} alt="" onClick={()=> window.open("https://www.linkedin.com/company/aanaab/", '_blank')}/>
          <img src={tw} className={style.icon} alt="" onClick={()=> window.open("https://twitter.com/aanaabcom?t=4y9fa1CVbv3dFEs0GN2RNg&s=09", '_blank')}/>
          <img src={ig} className={style.icon} alt="" onClick={()=> window.open("https://instagram.com/aanaabcom?igshid=YmMyMTA2M2Y=", '_blank')}/>
        </div>
       </div>
    </div></div>}
  </div>
)
}

export default Home;
