import style from './css/App.module.css';
import Login from './components/Login.js';
import NotFound from './components/NotFound.js';
import Reader from './components/Reader.js';
import Home from './components/Home.js';
import Navigator from './components/Navigator.js';
import { useState, useEffect } from "react";
import {BrowserRouter, Route, Routes, Link} from 'react-router-dom';

function App() {
  // npx cross-env BROWSER=none npm start
  // (node:16794) [DEP_WEBPACK_DEV_SERVER_ON_AFTER_SETUP_MIDDLEWARE] DeprecationWarning: 'onAfterSetupMiddleware' option is deprecated. Please use the 'setupMiddlewares' option.
  // (Use `node --trace-deprecation ...` to show where the warning was created)
  // (node:16794) [DEP_WEBPACK_DEV_SERVER_ON_BEFORE_SETUP_MIDDLEWARE] DeprecationWarning: 'onBeforeSetupMiddleware' option is deprecated. Please use the 'setupMiddlewares' option.
  const [readState, setReadState] = useState({courses: [{name:"dummy", book_id: 2, title: 'dd', url: "ssjs", store_link: null}], course: null});

  return (
    <BrowserRouter>
    <div className={style.App}>
      <Routes>
        <Route exact path="/" element={<Navigator flag={true}/>} />
        <Route exact path="/login" element={<Login setReaderState={setReadState} readerState={readState} />} />
        <Route exact path="/home" element={<Home readerState={readState} setReaderState={setReadState} />} />
        <Route exact path="/home/reader" element={<Reader readerState={readState} />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

// user token
// table names ***
// api route (localhost) ***
// postgres database connection ***
// encryption key  ***
// auth key in both ***
// twillio keys ***
// redirect links ***
export default App;
