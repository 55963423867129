import style from '../css/Login.module.css';
import { useState,useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../assets/aanaab-logo2.jpg';
import OneTimePassword from './OneTimePassword.js';
import axios from 'axios';

var Password = '';
var wrongMsg = {ar:"",en:""};
var codeRequests = 0;

function Login({setReaderState, readerState}){
  const navigate = useNavigate();
  const [LogInState, setLogInState]=useState({passwordOk: false, wrongInput: false,Progress:false, PassOTP: false, serverErr:false, otp: false});

  const handleChange = (e)=> {
      if (e.target.name == 'phone'){
        if((/\d/.test(e.target.value.substring(e.target.value.length-1)) && e.target.value.length <= 9) || e.target.value == ''){
          Password = e.target.value;
          validateInput(e.target.value);
        }
      }
    }

  useEffect(()=>{
    if(localStorage.getItem("token") != null)
      navigate('/');
  },[])


  const onKeyDown = (e)=>{
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      LoginAction();
    }
  }

  const validateInput = (input)=> {
      if(/^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(input))
        setLogInState({passwordOk: true, wrongInput: false,Progress:false, PassOTP:LogInState.PassOTP,serverErr:LogInState.serverErr, otp: false});
      else
        setLogInState({passwordOk: false, wrongInput: false,Progress:false, PassOTP:LogInState.PassOTP,serverErr:LogInState.serverErr, otp: false});
  }

  const LoginAction = ()=>{
    if(!LogInState.Progress){
      if(!LogInState.passwordOk){
          setLogInState({passwordOk: LogInState.passwordOk, wrongInput: true, Progress:LogInState.Progress, PassOTP:LogInState.PassOTP, serverErr:false, otp: false});
      }else{
          setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:true, PassOTP:LogInState.PassOTP,serverErr:false, otp: false});
          //////////////////////////////////////////////////////////
      }
    }
  }

  useEffect(() => {
    if(LogInState.Progress){
      axios.post('https://books.aanaab.com/api/login',{phone: Password},{headers:{authorization: process.env.REACT_APP_AUTH_KEY, Accept:'application/json'}})
        .then(response=>{
          if(response.data.responseCode==2){
              wrongMsg['en']="Too many requests please contact support";
              wrongMsg['ar']="عدة محاولات فاشلة لنفس الرقم الرجاء التواصل مع الدعم الفني";
              setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:false, PassOTP:LogInState.PassOTP,serverErr:true, otp: false});
          }
          else if (response.data.responseCode == 1){
              wrongMsg['en']="The phone number you entered is not registered";
              wrongMsg['ar']="رقم الجوال المدخل غير مسجل في النظام";
              setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:false, PassOTP:LogInState.PassOTP,serverErr:true, otp: false});
            }else if (response.data.responseCode == 3){
                if(!LogInState.PassOTP)
                  setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:false, PassOTP:true,serverErr:false, otp: false});
            }else{
              wrongMsg['en']="Error occured please try again";
              wrongMsg['ar']="حصل خطأ نرجو المحاولة مرة اخرى";
              setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:false, PassOTP:LogInState.PassOTP,serverErr:true, otp: false});
            }
        })
        .catch(error=>{
          wrongMsg['en']="Error occured please try again";
          wrongMsg['ar']="حصل خطأ نرجو المحاولة مرة اخرى";
          setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:false, PassOTP:LogInState.PassOTP,serverErr:true, otp: false});
        });
    }
  },[LogInState.Progress]);

  const cancelOTP = ()=>{
    if(!LogInState.Progress){
      setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false, Progress: false, PassOTP: !LogInState.PassOTP, serverErr: false, otp: false});
    }
  }


  const requestCode=()=>{
    codeRequests = codeRequests+1;
    if(codeRequests<=2){
      setLogInState({passwordOk: LogInState.passwordOk, wrongInput: false,Progress:true, PassOTP:LogInState.PassOTP,serverErr:false, otp: false});
      return true;
    }else{
      return false;
    }
  }

  return (
    <div className={style.LogInPage}>
    <div className={LogInState.serverErr ? style.LoginFailedBox : `${style.LoginFailedBox} ${style.hidden}`}>
      <p className={`${style.LoginFailedText} ${style.Arabic}`}>{wrongMsg.ar}</p>
    </div>
      <img src={logo} className={style.Logo} alt="" />
      <div className={style.LogInBox}>
        <p className={`${style.LogInHeaderText} ${style.Arabic}`}>اهلا بكم مجددا</p>
        <p className={`${style.LogInSubHeaderText} ${style.Arabic}`}>الرجاء ادخال رقم جوالكم بدون الصفر</p>
        <div className={style.PhoneBox}>
          <p className={`${style.PhoneCode} ${style.English}`}>+966</p>
          <form className={style.form}>
              <input className={`${style.EmailPassword} ${style.English}`} name="phone" value={Password} onChange={handleChange} placeholder={"رقم الجوال"} onKeyDown={onKeyDown}/>
            </form>
        </div>
        <button className={!LogInState.Progress && LogInState.passwordOk ? `${style.LogInButton} ${style.ActiveButton} ${style.Arabic}` : `${style.LogInButton} ${style.Arabic}`} onClick={()=> LoginAction()}>تسجيل الدخول</button>
      </div>
      <span className={!LogInState.Progress ? style.ProgressBar : `${style.ProgressBar} ${style.ProgressBarMovment}`}></span>
      {LogInState.PassOTP ? <OneTimePassword toggle={cancelOTP} phone={Password} requestCode={requestCode} /> : null}
    </div>
  );
}

export default Login;
