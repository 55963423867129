import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import style from '../css/NotFound.module.css';

function NotFound (){
 return(
  <div className={style.Container}>
    <p className={style.Text}>404</p>
    <p className={style.Text}>الصفحة غير موجودة</p>
    <button className={style.LogInButton} onClick={()=>window.location.replace('https://books.aanaab.com')}>الصفحة الرئيسية</button>
  </div>
 );
}

export default NotFound;
