import { useState, useEffect, useRef } from 'react'
import {useNavigate} from 'react-router-dom'
import style from '../css/Reader.module.css'
import logo from '../assets/aanaab-logo.jpg'
import axios from 'axios';

var pageNumber;
var justStarted = true;
var factor;

function Reader ({readerState}){
  const navigate = useNavigate();
  const [load, setLoad] = useState({loading:true, arbitrary:false});
  const [page, setPage] = useState(1);
  var x = useRef();

  if(localStorage.getItem("page")!=null)
    setPage(localStorage.getItem("page"));

  window.scrollTo(0,0);

  // check if there's a state value
  // yes: show book
  // no: navigate to notfound

  // state values
  // 1: book link
  // 2: book name
  // process.env.REACT_APP_AUTH_KEY
  useEffect(()=>{
    if (readerState.course === null){
      navigate('/home');
    }else{
      setTimeout(()=>{
        setLoad({loading:false, arbitrary:load.arbitrary});
        axios.post('https://books.aanaab.com/api/home/event',{reader_id: readerState.course.reader_id, book_id:readerState.course.book_id},{headers:{authorization:process.env.REACT_APP_AUTH_KEY, Accept:'application/json', token: localStorage.getItem("token")}})
        .catch(err =>{
          console.log('failed');
        })
      },2500);
    }
  },[])

  const handleChange = (e)=> {
    if (e.target.name == 'page'){
      if((/\d/.test(e.target.value.substring(e.target.value.length-1)) && e.target.value.length <= 3) || e.target.value == ''){
         pageNumber = e.target.value;
      }
      setLoad({loading:load.loading, arbitrary:load.arbitrary});
    }
  }

  const onKeyDown = (e)=>{
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      navigatePages(0);
    }
  }


  const back =()=>{
    navigate('/home');
  }

  const navigatePages = (flag)=>{
    if(flag == 0){
      if(pageNumber != '' && pageNumber != '0' && pageNumber.charAt(0) != '0'){
        setPage(parseInt(pageNumber));
        pageNumber = '';
      }
    }else if (flag == 1){
      window.innerWidth > window.innerHeight ? factor = 2 : factor = 1;
      setPage(page+factor);
      pageNumber = '';
    }else{
      if(page>0){
        window.innerWidth > window.innerHeight ? factor = 2 : factor = 1;
        setPage(page-factor);
      }
      pageNumber = '';
    }
  }

  const LoadingDesign =
    <div className={style.background}>
      <img src={require('../assets/loader2.gif')} className={style.loader} alt="" />
    </div>;


  return (
    <div className={style.container}>
    {load.loading ? LoadingDesign : <div className={style.container}>
      <div className={style.frameHolder}>
        <iframe id="a" className={style.frame} src={readerState.course.url+"#p="+page} seamless="seamless" scrolling="no" frameBorder="0"></iframe>
      </div>
      <div className={style.pageFlipper}>
      <p className={style.goBack2} onClick={()=> back()}>العودة</p>
      <button className={style.NavigatePage} onClick={()=> navigatePages(2)}>السابقة</button>
        <form className={style.form}>
            <input className={style.pageNumber} name="page" value={pageNumber} onChange={handleChange} placeholder={"رقم الصفحة"} onKeyDown={onKeyDown} />
        </form>
        <button className={style.NavigatePage} onClick={()=> navigatePages(1)}>التالية</button>
      </div>
      <div className={style.goBack}>
        <button className={style.NavigatePage} onClick={()=> back()}>العودة</button>
      </div>
      </div>}</div>
  )
}

export default Reader
