import style from '../css/Navigator.module.css';
import loader from '../assets/loader2.gif';
import {useNavigate} from 'react-router-dom'
import { useState, useEffect } from "react";

function Navigator({flag}) {
  const navigate = useNavigate();

  useEffect(()=>{
    if(flag){
      if(localStorage.getItem("token") != null)
        navigate('/home');
      else
        navigate('/login');
    }
  },[]);

  return (
    <div className={style.background}>
      <img src={loader} className={!flag ? style.loader : style.hide} alt="" />
    </div>
  );
}

export default Navigator;
